<template>
  <div>
    <!-- <b-btn>add request</b-btn> -->

    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :selectable="false"
      :columns="filteredColumns"
      :type="type"
      :per-page="perPage"
      :add-component-name="addComponentName"
      :selectcertificate="true"
      :certificate-options="certificate"
      :select-subcertificate="true"
    >
      <!-- certificate control -->
      <template #head(certificate)>
        <span v-if="isColumnVisible(certificate)">
          Certificate
        </span>
        <span
          class="text-dark cursor-pointer"
          :style="{ width: columnWidth, padding: columnPadding }"
          @click="toggleColumnVisibility(certificate)"
        >  <feather-icon
             v-if="isColumnVisible(certificate)"
             icon="ChevronLeftIcon"
             class="cursor-pointer"
             size="15"
           />
          <feather-icon
            v-else
            icon="ChevronRightIcon"
            class="cursor-pointer"
            size="15"
          /></span>
      </template>
      <template #cell(certificate)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div
            v-if="isColumnVisible(certificate)"
            :style="{ width: columnWidth, padding: columnPadding}"
          >
            <span style="font-size: smaller;">
              {{ data.item.certificate }}
            </span>
          </div>
        </slot>
      </template>
      <!-- sub certificate control -->

      <template #head(sub_certificate)>
        <span v-if="isColumnVisible(sub_certificate)">
          Sub Certificate
        </span>
        <span
          class="text-dark cursor-pointer"
          :style="{ width: columnWidth, padding: columnPadding }"
          @click="toggleColumnVisibility(sub_certificate)"
        > <feather-icon
            v-if="isColumnVisible(sub_certificate)"
            icon="ChevronLeftIcon"
            class="cursor-pointer"
            size="15"
          />
          <span v-else class="text-nowrap">
            <feather-icon

              icon="ChevronRightIcon"
              class="cursor-pointer"
              size="15"
            />
          </span>
        </span>
      </template>
      <template #cell(sub_certificate)="data">
        <div
          v-if="isColumnVisible(sub_certificate)"
          :style="{ width: columnWidth, padding: columnPadding}"
        >
          <div v-if="role === 'super-accountant' || role === 'accountant'">
            <span style="font-size: smaller;">
              {{ data.item.sub_certificate }}
            </span>
          </div>
          <div v-else>
            <div
              v-if="data.item.sub_certificate === null"
              v-b-popover.hover.topright="'edit sub certificate'"
              style="width: 100% !important; height: 24px !important"
              class="hover-pointer-empty text-center"
              @click="
                $router.push({
                  name: 'edit-sub-certificate',
                  params: { id: data.item.id }
                })
              "
            />
            <div
              v-b-popover.hover.topright="'edit sub certificate'"
              style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
              class="hover-pointer text-center"
              @click="
                $router.push({
                  name: 'edit-sub-certificate',
                  params: { id: data.item.id }
                })
              "
            >
              <span style="font-size: smaller;">
                {{ data.item.sub_certificate }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #cell(email_sended)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.email_sended === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span v-else>
              <feather-icon
                icon="XIcon"
                size="25"
                color="red"
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(require_audit)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span v-if="data.item.require_audit === 1">
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="#02111b"
              />
            </span>

            <span
              v-else
            ><feather-icon
              icon="XIcon"
              size="25"
              color="red"
            /></span>
          </div>
        </slot>
      </template>
      <template #cell(invoice)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span>
              <feather-icon
                v-b-popover.hover.topright="'show invoice'"
                icon="FileTextIcon"
                size="25"
                color="#02111b"
                style="cursor: pointer;"
                @click="
                  $router.push({
                    name: 'show-invoice',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>

      <template #cell(facility_name)="data">
        <div
          v-if="data.item.facility_name === ''"
          v-b-popover.hover.topright="'show facility info'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'show facility info'"
          class="hover-pointer text-center "
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          "
        >
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.facility_name }}
          </span>
        </div>
      </template>
      <template #cell(categoryRequestNo)="data">
        <span
          style="font-size: smaller;"
          class="text-nowrap px-1"
        >
          {{ data.item.categoryRequestNo }}
        </span>
      </template>
      <template #cell(last_status_update)="data">
        <span
          style="font-size: smaller;"
          class="text-nowrap px-1"
        >
          {{ data.item.last_status_update }}
        </span>
      </template>
      <template #cell(payment_status)="data">
        <div v-if="role === 'process-manager'">
          <span
            style="font-size: smaller;"
            class="text-nowrap"
          >
            {{ data.item.payment_status }}
          </span>
        </div>
        <div v-else>
          <div
            v-b-popover.hover.topright="'+ add payment'"
            class="hover-pointer text-center "
            style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
            @click="
              $router.push({
                name: 'edit-payment-status',
                params: { id: data.item.id }
              })
            "
          >
            <span
              style="font-size: smaller;"
              class="text-nowrap"
            >
              {{ data.item.payment_status }}
            </span>
          </div>
        </div>
      </template>
      <!-- Sticky Cell for Request Code -->
      <template #cell(request_code)="data">
        <a
          class="request-code-a"
          :href="
            `https://engineers.gulftic.com/orders/order/${data.item.id}?role=${accessRole}&name=${accessName}&email=${accessEmail}&token=${accessToken}`
          "
          target="_blank"
        >
          <div v-b-popover.hover.topright="'Go To Request'">
            <!-- @click="
            $router.push({
              name: 'request-histories',
              params: { id: data.item.id }
            })
          " -->

            <span style="font-size: smaller; text-wrap: nowrap">
              {{ data.item.request_code }}
            </span>
          </div>
        </a>
      </template>
      <!-- ----- test  -->
      <template #cell(offer)="data">
        <div
          v-if="data.item.offer === null"
          v-b-popover.hover.topright="'+ add offer'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add offer'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-offer',
              params: { id: data.item.id }
            })
          "
        >
          <span v-if="data.item.offer">
            {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
          </span>
        </div>
      </template>

      <template #cell(office)="data">
        <div
          v-if="data.item.office === null"
          v-b-popover.hover.topright="'+ add office'"
          style="width: 100% !important; height: 24px !important;font-size: smaller;"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add office'"
          class="hover-pointer text-center"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;font-size: smaller;"
          @click="
            $router.push({
              name: 'set-office',
              params: { id: data.item.id }
            })
          "
        >
          <span v-if="data.item.office">
            {{ data.item.office }}
          </span>
        </div>
      </template>

      <template #cell(first_reviewer)="data">
        <div
          v-if="data.item.first_reviewer === null"
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'first_reviewer' }
            })
          "
        />

        <div
          v-b-popover.hover.topright="'+ add first reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'first_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.first_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(second_reviewer)="data">
        <div
          v-if="data.item.second_reviewer === null"
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'second_reviewer' }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add second reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'second_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.second_reviewer }}
          </span>
        </div>
      </template>

      <template #cell(application_reviewer)="data">
        <div
          v-if="data.item.application_reviewer === null"
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'application_reviewer' }
            })
          "
        />
        <div
          v-b-popover.hover.topright="'+ add application reviewer'"
          style="height: 100% !important; display: flex; align-items: center; justify-content: center;"
          class="hover-pointer text-center"
          @click="
            $router.push({
              name: 'assign-engineer',
              params: { id: data.item.id, type: 'application_reviewer' }
            })
          "
        >
          <span style="font-size: smaller;">
            {{ data.item.application_reviewer }}
          </span>
        </div>
      </template>
      <template #cell(requester)="data">
        <span
          v-if="data.item.delegator"
          style="font-size: smaller;"
        >
          {{ data.item.delegator.name }}
        </span>
      </template>
    </general-table>
  </div>
</template>

<script>
// import { title } from '@/@core/utils/filter'
// import { mapState } from 'vuex'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { GeneralTable },
  data() {
    return {
      accessRole: localStorage.getItem('accessRole') || '',
      accessToken: localStorage.getItem('accessToken') || '',
      accessName: localStorage.getItem('name') || '',
      accessEmail: localStorage.getItem('email') || '',
      APIURL: 'requests',
      viewContent: true,
      type: 'page',
      perPage: 50,
      filter: {},
      addType: 'Add Request',
      addComponentName: 'add-request',
      certificate: [],
      columns: [
        // { key: 'id', sortable: false },
        {
          key: 'request_code',
          stickyColumn: true,
          isRowHeader: true,
          label: 'Request Code',
        },
        { key: 'categoryRequestNo', label: 'category Req. No' },
        { key: 'first_reviewer', label: 'first reviewer' },
        { key: 'second_reviewer', label: 'second reviewer' },
        { key: 'application_reviewer', label: 'application reviewer' },
        { key: 'certificate', label: 'Certificate' },
        { key: 'sub_certificate', label: 'Sub Certificate' },
        { key: 'source_date', label: 'Allocation Date' },
        { key: 'last_status_update', label: 'Last Status Update' },
        { key: 'last_status', label: 'Last Status' },
        { key: 'application_status', label: 'Application Status' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'source', label: 'Source' },
        { key: 'facility_name', label: 'Facility Name' },
        { key: 'requester', label: 'Requester' },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'payment_method', label: 'P.Method' },
        { key: 'location', label: 'Country' },
        { key: 'office', label: 'Payment Country' },
        { key: 'offer', label: 'Offer' },
        { key: 'certificate_price', label: 'Certificate Price' },
        { key: 'tax', label: 'Tax' },
        { key: 'other_tax', label: 'Other Tax' },
        { key: 'final_price', label: 'Final Price' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        // { key: 'edit_payment_status', label: 'Edit Payment Status' },
        { key: 'require_audit', label: 'Require Audit' },
        { key: 'email_sended', label: 'Email Sended' },
        // { key: 'set_offer', label: 'Set Offer' },
        // { key: 'assign_engineer', label: 'Assign Engineer' },
        { key: 'invoice', label: 'Invoice' },
      ],
      visibleColumns: ['certificate', 'sub_certificate'],
      columnWidths: {
        certificate: '3px',
        sub_certificate: '3px',
      }, // Initial width for the column
      columnPadding: '0px', // Initial padding for the column

      role: localStorage.getItem('accessRole'),
    }
  },
  computed: {
    filteredColumns() {
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'payment_method',
          'source',
          'certificate',
          'sub_certificate',
          'office',
          'offer',
          'location',
          'created_at',
          'final_price',
          'certificate_price',
          'wallet_balance',
          'facility_name',
          'facility',
          'invoice',
          'edit_payment_status',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'process-manager') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'first_reviewer',
          'second_reviewer',
          'application_reviewer',
          'created_at',
          'source',
          'location',
          'certificate',
          'sub_certificate',
          'require_audit',
          'assign_engineer',
          'requester',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'id',
          'categoryRequestNo',
          'source_date',
          'request_code',
          'payment_status',
          'payment_method',
          'location',
          'due_date',
          'source',
          'certificate',
          'sub_certificate',
          'offer',
          'created_at',
          'facility_name',
          'require_audit',
          'email_sended',
          'set_offer',
          'requester',
        ].includes(column.key))
      }
      return this.columns
    },
    apiUrl() {
      const base = 'requests?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },

  mounted() {
    axios
      .get('certificate_group')
      .then(res => {
        const dataCertificate = decryptData(res.data.payload)?.data
          ?.certificates
        this.certificate = dataCertificate.map(item => ({
          value: item.id,
          text: `${item.name?.ar} - ${item.name?.en}`,
        }))
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
    toggleColumnVisibility(columnKey) {
      const isVisible = this.visibleColumns.includes(columnKey)

      if (isVisible) {
        // Remove the column from visibleColumns and set its width to '3px'
        this.visibleColumns = this.visibleColumns.filter(col => col !== columnKey)
        this.$set(this.columnWidths, columnKey, '3px')
      } else {
        // Add the column to visibleColumns and set its width to 'auto'
        this.visibleColumns.push(columnKey)
        this.$set(this.columnWidths, columnKey, 'auto')
      }
    },
    isColumnVisible(columnKey) {
      return this.visibleColumns.includes(columnKey)
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-pointer:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 8.5px 0px;
}
.request-code-a {
  cursor: text;
}
.request-code-a:hover {
  color: white; /* Change the property you want to affect on hover */
}
.hover-pointer-empty:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 14.5px 0px;
}
</style>
